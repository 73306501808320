import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vue3SeamlessScroll from "vue3-seamless-scroll";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import 'normalize.css/normalize.css';
const app = createApp(App)
app.use(ElementPlus)
app.use(store).use(router).use(vue3SeamlessScroll).mount('#app')
 