import { createRouter, createWebHashHistory } from 'vue-router'
import TestView from '../views/kanban/Test.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/monitoring'
  },
  {
    path: '/monitoring',
    name: 'Monitoring',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Monitoring" */ '@/views/kanban/Monitoring.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
